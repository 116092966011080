@import "../styles/fonts";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  hr {
    @apply border-hr-light/20 dark:border-hr-dark/20 border-t;
  }
}

@layer components {
  :focus {
    @apply outline-none ring-1 ring-brand-primaryHoverColor;
  }

  :focus-visible {
    @apply outline-none ring-2 ring-brand-primaryHoverColor;
  }

  .btn {
    @apply px-3.5 py-2.5 text-lg font-semibold shadow-sm rounded-md no-underline;
  }

  .btn-primary {
    @apply bg-brand-secondaryLightColor text-brand-primaryLightColor hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-primaryColor disabled:bg-neutral-500 disabled:hover:no-underline disabled:cursor-not-allowed;
  }

  .btn-cta {
    @apply bg-brand-linkColor text-white dark:bg-brand-primaryColor dark:text-brand-secondaryColor hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-secondaryLightColor;
  }

  .btn-link {
    @apply font-semibold;
  }

  .btn-link-inverted {
    @apply text-brand-secondaryLightColor font-semibold;
  }

  label {
    @apply text-neutral-900 dark:text-neutral-200;
  }

  select[multiple] option:checked {
    @apply bg-brand-primaryColor text-white;
  }

  select[multiple] option:active {
    @apply bg-brand-primaryColor text-white;
  }

  select[multiple] option:checked:active {
    @apply bg-brand-primaryColor text-white;
  }

  .tight-rich-text-block p[data-block-key] {
    line-height: 1.5rem;
  }

  .carousel p[data-block-key] {
    @apply text-neutral-900 dark:text-neutral-50;
  }

  .hero-section p[data-block-key] {
    @apply text-2xl leading-8 text-neutral-500 dark:text-neutral-300 font-medium;
  }
}
